import { graphql } from "gatsby"
import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo.component"
import { AppFunctionComponent } from "../../types"
import { NotFoundProps } from "./../pl/404"
import EUPageContent from "../../ui/components/eu-page/eu-page.component"

const EUPagePL: AppFunctionComponent<NotFoundProps> = ({
  data: {
    topNavigationContent: { navigationItems: topNavigationItems },
    footerNavigationContent: { navigationItems: footerNavigationItems },
    promobarContent,
  },
}) => (
  <Layout
    promobarContent={promobarContent}
    topNavigationItems={topNavigationItems}
    footerNavigationItems={footerNavigationItems}
  >
    <SEO title="Projekty Unii Europejskiej" />
    <EUPageContent />
  </Layout>
)

export const query = graphql`
  query EUQueryPl($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    topNavigationContent: contentfulNavigation(
      contentfulid: { eq: "top-navigation" }
      node_locale: { eq: "pl-PL" }
    ) {
      ...Navigation
      node_locale
    }
    footerNavigationContent: contentfulNavigation(
      contentfulid: { eq: "footer-navigation" }
      node_locale: { eq: "pl-PL" }
    ) {
      ...Navigation
      node_locale
    }
    promobarContent: contentfulText(
      contentful_id: { eq: "67Y5XVRqB0r7iJbO4N8zxx" }
      node_locale: { eq: "pl-PL" }
    ) {
      text {
        raw
      }
      node_locale
    }
  }
`

export default EUPagePL
